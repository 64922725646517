import kebabCase from 'lodash/kebabCase';
import type { SyntheticEvent } from 'react';
import * as React from 'react';

import { generateTestId } from '../../lib/test-helpers';
import { cn } from '../../v2';
import { IconSquareExternal } from '../icons';

interface Props {
  label: string;
  href?: string;
  target?: string;
  onClick?: (e: SyntheticEvent) => unknown;
  disabled?: boolean;
  testId?: string;
  className?: string;
}

export function TileLink({
  label,
  href,
  target,
  onClick,
  disabled = false,
  testId: propTestId,
  className,
}: Props): JSX.Element {
  const testId = propTestId ?? generateTestId(kebabCase(label), 'tile-link');
  return (
    <a
      className={cn(
        'tile-link group block cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap',
        'rounded-md border no-underline shadow-[0px_2px_0px_rgba(0,0,0,0.04)] transition-all duration-300',
        'box-border p-5 hover:border-brand-400 hover:shadow-[0px_2px_0px_rgba(0,0,0,0.08)]',
        'flex items-center justify-between gap-2 border-border/50',
        {
          'pointer-events-none opacity-60': disabled,
        },
        className,
      )}
      href={href}
      target={target}
      onClick={!disabled ? onClick : undefined}
      data-disabled={disabled}
      data-testid={testId}
    >
      <span className="block text-base">{label}</span>
      <span className="text-brand-400 opacity-0 transition-opacity duration-300 group-hover:opacity-100">
        <IconSquareExternal color="currentColor" />
      </span>
    </a>
  );
}

interface ListProps {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
}

export function TileLinkList({ children, className }: ListProps): JSX.Element {
  return (
    <div className={cn('grid w-full grid-cols-2 gap-3', className)}>
      {Array.isArray(children) ? children.map((child) => child) : children}
    </div>
  );
}
