import { cn } from '@newfront-insurance/core-ui/v2';
import * as React from 'react';

import { BottomLinkComponent } from './components/bottom-button';
import { NavigationLinkGroup } from './components/group';
import type { BottomLink, LinkGroup } from './types';
import { HomeLink } from '../app-header';

interface Props {
  linkGroups?: LinkGroup[];
  children: React.ReactNode;
  bottomLink?: BottomLink;
  isShowingDarkSidebar?: boolean;
  pageNavigation?: React.ReactNode;
}

function createGroupKey(group: LinkGroup): string {
  return btoa(group.links.map((link) => link.label).join('-'));
}

export function PageSidebar(props: Props): JSX.Element {
  const { linkGroups, children, bottomLink, isShowingDarkSidebar = false, pageNavigation } = props;

  return (
    <div className="flex h-full items-stretch overflow-y-hidden">
      <div className="flex flex-col border-r">
        {isShowingDarkSidebar && (
          <div className="block bg-[#152023] px-6 pb-2 pt-4 dark:bg-background">
            <HomeLink isShowingDarkSidebar={isShowingDarkSidebar} />
          </div>
        )}

        {linkGroups && (
          <div
            className={cn(
              'box-border flex h-full w-[280px] flex-col justify-between overflow-y-auto bg-white px-4 py-6 dark:bg-background',
              { 'pb-0': bottomLink },
              { 'pt0 bg-[#152023]': isShowingDarkSidebar },
            )}
          >
            <div className="flex flex-col gap-10">
              {linkGroups.map((group) => (
                <NavigationLinkGroup
                  group={group}
                  key={createGroupKey(group)}
                  isShowingDarkSidebar={isShowingDarkSidebar}
                />
              ))}
            </div>
            {bottomLink && (
              <div className="sticky bottom-0">
                <div className={cn('bg-white pb-6 pt-4 dark:bg-background', { 'bg-[#152023]': isShowingDarkSidebar })}>
                  <BottomLinkComponent bottomLink={bottomLink} isShowingDarkSidebar={isShowingDarkSidebar} />
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="relative flex w-full flex-col overflow-y-auto">
        {isShowingDarkSidebar && pageNavigation && (
          <div className="flex flex-row border-b-[1px] bg-white dark:bg-background">{pageNavigation}</div>
        )}
        {children}
      </div>
    </div>
  );
}
