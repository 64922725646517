import { Stack, Button, IconExternal, ButtonMenu2, IconPencil } from '@newfront-insurance/core-ui';

import type { PageAction } from '../types';

export function ActionButton(props: PageAction): JSX.Element {
  const { disabled, isExternal, isEdit, dropDownItems, label, link, loading, variant = 'secondary' } = props;

  return dropDownItems ? (
    <ButtonMenu2
      style={{
        height: 32,
        lineHeight: '30px',
        paddingLeft: '24px',
        paddingRight: '24px',
      }}
      variant={variant}
      items={dropDownItems}
    >
      {label}
    </ButtonMenu2>
  ) : (
    <Button
      style={{
        height: 32,
        lineHeight: '30px',
      }}
      target={isExternal ? '_blank' : undefined}
      href={link.href}
      onClick={link.onClick}
      size={variant}
      loading={loading}
      disabled={disabled}
    >
      <Stack gap={8} alignItems="center">
        {label}
        {isExternal && <IconExternal />}
        {isEdit ? <IconPencil /> : undefined}
      </Stack>
    </Button>
  );
}
