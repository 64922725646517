import { TileLink, Tooltip } from '@newfront-insurance/core-ui';
import * as React from 'react';

import { isActionLink, isRouteLink } from '../helpers/links';
import type { BottomLink } from '../types';

interface Props {
  bottomLink: BottomLink;
  isShowingDarkSidebar?: boolean;
}

export function BottomLinkComponent(props: Props): JSX.Element {
  const { bottomLink, isShowingDarkSidebar = false } = props;
  const { linkItem } = bottomLink;

  if (isActionLink(linkItem)) {
    const { label, onClick, isActive = false, isDisabled = false, tooltipText } = linkItem;

    return (
      <Tooltip message={tooltipText}>
        <TileLink
          label={label}
          onClick={onClick}
          data-active={isActive}
          disabled={isDisabled}
          className={isShowingDarkSidebar ? 'text-white' : undefined}
        />
      </Tooltip>
    );
  }

  if (isRouteLink(linkItem)) {
    const { label, link, badgeCount, isDisabled = false, target, tooltipText } = linkItem;
    return (
      <Tooltip message={tooltipText}>
        <TileLink
          label={label}
          data-active={link.isActive}
          onClick={link.onClick as (e: React.SyntheticEvent) => unknown}
          data-count={badgeCount}
          disabled={isDisabled}
          target={target}
          href={link.href}
          className={isShowingDarkSidebar ? 'text-white' : undefined}
        />
      </Tooltip>
    );
  }

  // External link
  return (
    <Tooltip message={linkItem.tooltipText}>
      <TileLink
        href={linkItem.href}
        disabled={linkItem.isDisabled}
        label={linkItem.label}
        target={linkItem.target}
        className={isShowingDarkSidebar ? 'text-white' : 'inherit'}
      />
    </Tooltip>
  );
}
