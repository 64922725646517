/* eslint-disable react/jsx-props-no-spreading */
import { Stack } from '@newfront-insurance/core-ui';

import { ActionButton } from './action-button';
import type { PageAction } from '../types';

interface PageHeaderActionProps {
  buttons: PageAction[];
}

export function PageHeaderActions(props: PageHeaderActionProps): JSX.Element | null {
  const { buttons } = props;

  if (buttons.length === 0) {
    return null;
  }

  return (
    <Stack gap={4} justifyContent="flex-end" alignItems="center">
      {buttons.map((button) => (
        <ActionButton key={button.label} {...button} />
      ))}
    </Stack>
  );
}
