import type { MenuItem2 as MenuItem } from '@newfront-insurance/core-ui';
import { Menu2 as Menu, UncontrolledPopper, IconButton, MenuItemType } from '@newfront-insurance/core-ui';
import { cn } from '@newfront-insurance/core-ui/v2';
import * as React from 'react';

import { BackButton } from './components/back-button';
import { Breadcrumbs } from './components/breadcrumbs';
import { PageHeaderActions } from './components/page-header-actions';
import type { BackOptions, Breadcrumb, OverflowAction, OverflowActionGroup, PageAction } from './types';

interface Props {
  title: React.ReactNode;
  subtitle?: string;
  actions?: PageAction[];
  breadcrumbs?: Breadcrumb[];
  additionalActions?: React.ReactNode;
  overflowActions?: OverflowAction[];
  overflowActionsGroup?: OverflowActionGroup[];
  onBack?: BackOptions;
}

export function PageHeader(props: Props): JSX.Element | null {
  const {
    title,
    subtitle,
    actions = [],
    onBack,
    additionalActions,
    overflowActions,
    overflowActionsGroup,
    breadcrumbs,
  } = props;

  if (!title && !additionalActions) return null;

  const hasActions =
    !!additionalActions ||
    actions.length > 0 ||
    (overflowActions && overflowActions.length > 0) ||
    (overflowActionsGroup && overflowActionsGroup.length > 0);

  return (
    <div className="flex h-20 items-center gap-6 border-b-2 border-steel-150 bg-white px-6">
      {onBack && <BackButton label={onBack.label} link={onBack.link} />}

      <div
        className={cn('flex w-full items-center justify-between', {
          'justify-between': title,
          'justify-end': !title && hasActions,
        })}
      >
        {title && (
          <div className="flex flex-col items-start gap-0">
            {typeof title === 'string' ? <span className="text-gray-500 text-lg">{title}</span> : title}
            {breadcrumbs && <Breadcrumbs crumbs={breadcrumbs} />}
            {subtitle && <span className="text-gray-400 text-sm">{subtitle}</span>}
          </div>
        )}

        {hasActions && (
          <div className="flex items-center gap-3">
            {additionalActions && <div className="flex content-center">{additionalActions}</div>}
            {actions && <PageHeaderActions buttons={actions} />}
            {(overflowActions || overflowActionsGroup) && (
              <UncontrolledPopper
                // eslint-disable-next-line react/no-unstable-nested-components
                trigger={({ toggle, triggerRef }) => (
                  <IconButton type="more" onClick={() => toggle()} elementRef={triggerRef} />
                )}
              >
                {({ toggle }) => (
                  <Menu onItemClick={toggle} items={createMenuItems(overflowActions, overflowActionsGroup)} />
                )}
              </UncontrolledPopper>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

function createMenuItems(
  actions: OverflowAction[] | undefined,
  actionsGroup: OverflowActionGroup[] | undefined,
): MenuItem[] {
  const items: MenuItem[] = [];

  actions?.forEach((action: OverflowAction) => {
    items.push(createMenuLink(action as OverflowAction));
  });

  actionsGroup?.forEach((actionGroup: OverflowActionGroup) => {
    if (actionGroup.title) {
      items.push({ type: MenuItemType.TITLE, title: actionGroup.title });
    }

    actionGroup.links.forEach((actionGroupItem) => {
      items.push(createMenuLink(actionGroupItem));
    });
  });

  return items;
}

function createMenuLink(action: OverflowAction): MenuItem {
  const { target } = action;
  const { href, onClick } = action.link;
  return {
    type: MenuItemType.LINK,
    label: action.label,
    onClick,
    href,
    target,
  };
}
