import type { Link as RouterLink } from '@newfront-insurance/next-router-provider';

export interface Link {
  href?: string;
  onClick?: RouterLink['onClick'];
  isActive?: boolean;
}

export enum TabIcon {
  BIND = 'BIND',
  COMPARE = 'COMPARE',
  FILES = 'FILES',
  FOLDER = 'FOLDER',
  PLAN = 'PLAN',
  PREPARE = 'PREPARE',
  PROPOSE = 'PROPOSE',
  SUBMISSION_RESPONSE = 'SUBMISSION_RESPONSE',
  SUBMIT = 'SUBMIT',
}

export interface TabLink {
  label: string;
  link: Link;
  icon: TabIcon;
}
