import { cn } from '@newfront-insurance/core-ui/v2';

import { NavigationItem } from './item';
import type { LinkGroup } from '../types';

interface Props {
  group: LinkGroup;
  isShowingDarkSidebar?: boolean;
}

export function NavigationLinkGroup(props: Props): JSX.Element {
  const { group, isShowingDarkSidebar = false } = props;

  return (
    <div className="flex flex-col gap-2">
      {group.title ? (
        <div>
          <div className={cn('pl-2 text-sm font-semibold uppercase leading-5 tracking-widest text-steel-300')}>
            {group.title}
          </div>
        </div>
      ) : null}
      <div className="flex flex-col gap-0.5 ">
        {group.links.map((linkItem) => {
          return (
            <NavigationItem linkItem={linkItem} key={linkItem.label} isShowingDarkSidebar={isShowingDarkSidebar} />
          );
        })}
      </div>
    </div>
  );
}
