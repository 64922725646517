import kebabCase from 'lodash/kebabCase';
import * as React from 'react';
import type { CSSProperties } from 'styled-components';

import { generateTestId } from '../../lib/test-helpers';
import type { ButtonVariant } from '../button';
import { Button } from '../button';
import { Flexbox } from '../flexbox';
import { IconCaret } from '../icons';
import type { MenuItem } from '../menu-2';
import { Menu } from '../menu-2';
import { Popper } from '../popper';
import { Spacing } from '../spacing';

interface Props {
  items: MenuItem[];
  children: React.ReactNode;
  width?: number;
  variant?: ButtonVariant;
  style?: CSSProperties;
  testId?: string;
}

export function ButtonMenu2({
  children,
  variant = 'primary',
  items,
  width,
  style,
  testId: propTestId,
}: Props): JSX.Element {
  const testId =
    propTestId ??
    (typeof children === 'string' ? generateTestId(kebabCase(children.toString()), 'button-menu') : undefined);

  return (
    <>
      <Popper
        trigger={({ triggerRef, toggle }) => (
          <Button
            type="button"
            size={variant}
            elementRef={triggerRef}
            onClick={toggle}
            minWidth={width}
            style={style}
            testId={testId}
          >
            <Flexbox justifyContent="center" alignItems="center">
              {children}
              <Spacing width={4} />
              <IconCaret color="currentColor" />
            </Flexbox>
          </Button>
        )}
      >
        {({ toggle }) => <Menu items={items} onItemClick={toggle} />}
      </Popper>
    </>
  );
}
