import { cn } from '@newfront-insurance/core-ui/v2';
import { useNewfrontLocalStorage } from '@newfront-insurance/react-hooks';

import { isRouteLink } from '../helpers/links';
import type { ExternalLink, RouteLink } from '../types';

interface Props {
  children: React.ReactNode;
  linkItem: RouteLink | ExternalLink;
  isShowingDarkSidebar?: boolean;
}

// default classes
function defaultLinkClasses(isShowingDarkSidebar?: boolean) {
  return `box-border flex cursor-pointer items-center justify-between
  overflow-hidden px-2 leading-8 text-steel-500 rounded-sm no-underline
  ${isShowingDarkSidebar ? 'hover:bg-steel-500 hover:text-white' : 'hover:bg-steel-100 hover:text-brand-300'}`;
}
// with data-count
const dataAttributeCountClasses = `data-[count]:after:ml-2 data-[count]:after:block data-[count]:after:rounded-sm
data-[count]:after:bg-brand-100 data-[count]:after:px-1.5 data-[count]:after:py-0.5 data-[count]:after:text-sm
data-[count]:after:leading-4 data-[count]:after:text-brand-400 data-[count]:after:content-[attr(data-count)]`;

// with data-active="true"
const dataAttributeActiveClasses = 'data-[active=true]:bg-brand-400 data-[active=true]:text-brand-100';

// with both data-count and data-active="true"
const dataAttributesCountAndActiveClasses =
  'data-[active=true]:data-[count]:after:bg-brand-300 data-[active=true]:data-[count]:after:text-brand-100';

// with data-icon="external"
const dataAttributeIconClasses = 'data-[icon=external]:after:block';

// with data-disabled="true"
const dataAttributeDisabledClasses = `
  data-[disabled=true]:opacity-50
  data-[disabled=true]:pointer-events-none
  `;

export function getNavigationlinkClassNames(isShowingDarkSidebar?: boolean): string {
  return cn(
    defaultLinkClasses(isShowingDarkSidebar),
    dataAttributeCountClasses,
    dataAttributeActiveClasses,
    dataAttributeIconClasses,
    dataAttributesCountAndActiveClasses,
    dataAttributeDisabledClasses,
    {
      'text-steel-100': isShowingDarkSidebar,
    },
  );
}

export function NavigationLink({ children, linkItem, isShowingDarkSidebar }: Props): JSX.Element {
  const navigationlinkStyles = getNavigationlinkClassNames(isShowingDarkSidebar);
  const [subscribed, setSubscribed] = useNewfrontLocalStorage<boolean>(linkItem.betaKey ?? 'noop', false, {
    raw: false,
    serializer: (value) => value.toString(),
    deserializer: (value) => !!value,
  });
  if (isRouteLink(linkItem)) {
    const { link, badgeCount, isDisabled = false, target = '_self', isActive } = linkItem;

    return (
      <div data-disabled={isDisabled} className="data-[disabled=true]:cursor-not-allowed">
        <a
          className={navigationlinkStyles}
          data-active={isActive === undefined ? link.isActive : isActive}
          href={link.href}
          onClick={(e) => {
            if (linkItem.betaKey) {
              setSubscribed(true);
            }
            link.onClick(e);
          }}
          data-count={badgeCount}
          data-disabled={isDisabled}
          target={target}
        >
          {children}
          {!isActive && !subscribed && linkItem.betaKey && (
            <div className="">
              <span className="relative flex h-3 w-3">
                <span className="absolute inline-flex h-full w-full animate-ping rounded-lg bg-rebrand-300/50 opacity-75" />
                <span className="relative inline-flex h-3 w-3 rounded-lg bg-rebrand-400/30" />
              </span>
            </div>
          )}
        </a>
      </div>
    );
  }

  return (
    <a
      className={navigationlinkStyles}
      href={linkItem.href}
      data-disabled={linkItem.isDisabled}
      target={linkItem.target}
    >
      {children}
    </a>
  );
}
