import type { ActionLink, ExternalLink, LinkItem, RouteLink } from '../types';
import { LinkType } from '../types';

export function isRouteLink(linkItem: LinkItem): linkItem is RouteLink {
  return linkItem.type === LinkType.ROUTE;
}

export function isExternalLink(linkItem: LinkItem): linkItem is ExternalLink {
  return linkItem.type === LinkType.EXTERNAL;
}

export function isActionLink(linkItem: LinkItem): linkItem is ActionLink | Omit<ActionLink, 'showExternalIcon'> {
  return linkItem.type === LinkType.ACTION;
}
