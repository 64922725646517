import type { Link as RouterLink } from '@newfront-insurance/next-router-provider';
import type React from 'react';

export interface LinkGroup {
  title?: string;
  links: LinkItem[];
}

export enum LinkType {
  'ROUTE' = 'ROUTE',
  'EXTERNAL' = 'EXTERNAL',
  'ACTION' = 'ACTION',
}

export type LinkItem = ActionLink | RouteLink | ExternalLink;

type BottomLinkItem = Omit<ActionLink, 'showExternalIcon'> | RouteLink | ExternalLink;

export interface ActionLink {
  type: LinkType.ACTION;
  isActive?: boolean;
  isDisabled?: boolean;
  label: string;
  onClick: (e: React.SyntheticEvent | React.MouseEvent) => void;
  showExternalIcon?: boolean;
  tooltipText?: string;
  betaKey?: string;
}

export interface ExternalLink {
  type: LinkType.EXTERNAL;
  label: string;
  href: string;
  target?: string;
  tooltipText?: string;
  isDisabled?: boolean;
  betaKey?: string;
}

export interface RouteLink {
  type: LinkType.ROUTE;
  label: string;
  link: RouterLink;
  badgeCount?: number;
  icon?: JSX.Element;
  rightIcon?: JSX.Element;
  target?: string;
  tooltipText?: string;
  isDisabled?: boolean;
  isActive?: boolean;
  betaKey?: string;
}

// We may want to add support for custom Icon and color in future.
export interface BottomLink {
  linkItem: BottomLinkItem;
}
