import { CircleSpinner } from '@newfront-insurance/core-ui';
import { cn } from '@newfront-insurance/core-ui/v2';
import * as React from 'react';
import { Suspense } from 'react';

import { InternalAppErrorBoundary } from '../error-boundary';
import { PageHeader } from '../page-header';
import type { BackOptions, Breadcrumb, OverflowAction, OverflowActionGroup, PageAction } from '../page-header/types';
import { PageSidebar } from '../page-sidebar';
import type { BottomLink, LinkGroup } from '../page-sidebar/types';
import { PageTabs } from '../page-tabs';
import type { TabLink } from '../page-tabs/types';

// When set to tailwind, the background will be the tailwind color palette ie. bg-background
type PageBackground = 'white' | 'gray' | 'tailwind';

interface Props {
  pageTitle?: React.ReactNode;
  pageSubtitle?: string;
  onBack?: BackOptions;
  breadcrumbs?: Breadcrumb[];
  bottomLink?: BottomLink;
  pagePrimaryActions?: PageAction[];
  pageOverflowActions?: OverflowAction[];
  pageOverflowGroupActions?: OverflowActionGroup[];
  pageAdditionalHeaderContent?: React.ReactNode;
  pageLinks?: LinkGroup[];
  pageTabs?: TabLink[];
  children: React.ReactNode;
  background?: PageBackground;
  isShowingDarkSidebar?: boolean;
  pageNavigation?: React.ReactNode;
}

export function PageLayout(props: Props): JSX.Element {
  const {
    pageTitle,
    pageTabs,
    pageSubtitle,
    onBack,
    breadcrumbs,
    bottomLink,
    pagePrimaryActions,
    pageOverflowActions,
    pageOverflowGroupActions,
    pageAdditionalHeaderContent,
    pageLinks,
    children,
    background = 'tailwind',
    isShowingDarkSidebar = false,
    pageNavigation,
  } = props;

  return (
    <div className="flex h-full flex-1 flex-col overflow-hidden">
      <PageHeader
        title={pageTitle}
        subtitle={pageSubtitle}
        onBack={onBack}
        breadcrumbs={breadcrumbs}
        actions={pagePrimaryActions}
        overflowActions={pageOverflowActions}
        overflowActionsGroup={pageOverflowGroupActions}
        additionalActions={pageAdditionalHeaderContent}
      />
      {pageTabs && <PageTabs tabs={pageTabs} />}
      <div
        className={cn('h-full flex-1 overflow-y-auto', {
          'bg-white': background === 'white',
          'bg-steel-100': background === 'gray',
          'bg-background': background === 'tailwind',
        })}
      >
        <PageSidebar
          linkGroups={pageLinks}
          bottomLink={bottomLink}
          isShowingDarkSidebar={isShowingDarkSidebar}
          pageNavigation={pageNavigation}
        >
          <Suspense fallback={<CircleSpinner />}>
            <InternalAppErrorBoundary>{children}</InternalAppErrorBoundary>
          </Suspense>
        </PageSidebar>
      </div>
    </div>
  );
}
