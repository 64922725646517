import { colors, IconArrow, Tooltip } from '@newfront-insurance/core-ui';
import styled from 'styled-components';

import type { Link } from '../../../../types';

interface Props {
  label: string;
  link: Link;
}

export function BackButton(props: Props): JSX.Element {
  const { label, link } = props;

  return (
    <Tooltip message={label}>
      <StyledBackButton href={link.href} onClick={link.onClick}>
        <IconArrow />
      </StyledBackButton>
    </Tooltip>
  );
}

const StyledBackButton = styled.a`
  cursor: pointer;
  position: relative;
  align-items: center;
  display: flex;
  width: fit-content;

  &:hover {
    background: ${colors.steel[100]};
    border-radius: 100px;
    box-shadow: 0 0 0 10px ${colors.steel[100]};
  }
`;
