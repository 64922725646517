/* eslint-disable max-len */
import { Flexbox, IconExternal2 as ExternalIcon, Tooltip } from '@newfront-insurance/core-ui';

import { NavigationLink, getNavigationlinkClassNames } from './navigation-link';
import { isActionLink, isRouteLink } from '../helpers/links';
import type { LinkItem } from '../types';

interface Props {
  linkItem: LinkItem;
  isShowingDarkSidebar?: boolean;
}

export function NavigationItem(props: Props): JSX.Element {
  const { linkItem, isShowingDarkSidebar = false } = props;

  const navigationlinkClassNames = getNavigationlinkClassNames(isShowingDarkSidebar);
  const linkTextStyles = 'text-ellipsis whitespace-nowrap	overflow-hidden';

  if (isActionLink(linkItem)) {
    const { label, onClick, isActive = false, isDisabled = false, showExternalIcon = false, tooltipText } = linkItem;

    return (
      <Tooltip message={tooltipText}>
        <button
          className={navigationlinkClassNames}
          onClick={onClick}
          data-active={isActive}
          data-disabled={isDisabled}
          type="button"
        >
          <div className={linkTextStyles}>{label}</div>
          {showExternalIcon && <ExternalIcon />}
        </button>
      </Tooltip>
    );
  }

  if (isRouteLink(linkItem)) {
    const { label, icon, tooltipText, rightIcon } = linkItem;

    return (
      <Tooltip message={tooltipText}>
        <NavigationLink linkItem={linkItem} isShowingDarkSidebar={isShowingDarkSidebar}>
          {icon ? (
            <Flexbox flexDirection="row" alignItems="center" gap={8} paddingLeft={8}>
              {icon}
              <div className={linkTextStyles}>{label}</div>
            </Flexbox>
          ) : (
            <div className="flex w-full flex-row items-center justify-between gap-24">
              <div className={linkTextStyles}>{label}</div>
              {rightIcon}
            </div>
          )}
        </NavigationLink>
      </Tooltip>
    );
  }

  // External link or Conditional link with activeType set to external
  return (
    <Tooltip message={linkItem.tooltipText}>
      <NavigationLink linkItem={linkItem} isShowingDarkSidebar={isShowingDarkSidebar}>
        <div className={linkTextStyles}>{linkItem.label}</div>
        <ExternalIcon />
      </NavigationLink>
    </Tooltip>
  );
}
