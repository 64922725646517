import { Text, colors, Stack, TextLink } from '@newfront-insurance/core-ui';

import type { Breadcrumb } from '../types';

interface BreadcrumbsProps {
  crumbs: Breadcrumb[];
}

export function Breadcrumbs(props: BreadcrumbsProps): JSX.Element {
  const { crumbs } = props;

  return (
    <Stack direction="horizontal" gap={4}>
      {crumbs.map((crumb, i) => {
        if (i === crumbs.length - 1) {
          return (
            <Text key={crumb.label} size="small" color={colors.steel[400]}>
              {crumb.label}
            </Text>
          );
        }

        return (
          <Text key={crumb.label} size="small" color={colors.steel[400]}>
            <Stack direction="horizontal" gap={4} alignItems="center">
              {crumb.link ? (
                <TextLink
                  underline={false}
                  variant="secondary"
                  style={{ font: 'inherit' }}
                  href={crumb.link.href}
                  onClick={crumb.link.onClick}
                >
                  {crumb.label}
                </TextLink>
              ) : (
                <Text key={crumb.label} size="small" color={colors.steel[400]}>
                  {crumb.label}
                </Text>
              )}

              <svg height="8" width="8" viewBox="0 0 12 12">
                <g fill={colors.steel[400]}>
                  <path
                    d="M11.707,5.293,7,.586,5.586,2l3,3H0V7H8.586l-3,3L7,11.414l4.707-4.707A1,1,0,0,0,11.707,5.293Z"
                    fill={colors.steel[400]}
                  />
                </g>
              </svg>
            </Stack>
          </Text>
        );
      })}
    </Stack>
  );
}
