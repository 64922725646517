import { cn } from '@newfront-insurance/core-ui/v2';

import type { IconComponent } from './components/icons';
import {
  FilesIcon,
  BindIcon,
  CompareIcon,
  FolderIcon,
  PlanIcon,
  PrepareIcon,
  ProposeIcon,
  SubmitIcon,
} from './components/icons';
import type { TabLink } from './types';
import { TabIcon } from './types';

interface Props {
  tabs: TabLink[];
}

const ICON_MAP: Record<TabIcon, IconComponent> = {
  [TabIcon.FILES]: FilesIcon,
  [TabIcon.BIND]: BindIcon,
  [TabIcon.COMPARE]: CompareIcon,
  [TabIcon.FOLDER]: FolderIcon,
  [TabIcon.PLAN]: PlanIcon,
  [TabIcon.PREPARE]: PrepareIcon,
  [TabIcon.PROPOSE]: ProposeIcon,
  [TabIcon.SUBMIT]: SubmitIcon,
  [TabIcon.SUBMISSION_RESPONSE]: (): JSX.Element => <SubmitIcon className="rotate-180 transform" />,
};

export function PageTabs(props: Props): JSX.Element {
  const { tabs } = props;

  return (
    <nav className="sticky top-0 border-b-[1px] border-steel-150 bg-white px-6 py-0">
      <nav className="relative flex h-12 justify-start">
        <div className="flex gap-8">
          {tabs.map((tab) => {
            const Icon = ICON_MAP[tab.icon];
            return (
              <a
                className={cn('text-decoration-none cursor-pointer', {
                  'shadow-[inset_0_-4px_0_#0957C3]': tab.link.isActive,
                  'hover:shadow-[inset_0_-4px_0_#BEC7D0]': !tab.link.isActive,
                  'shadow-none': !tab.link.isActive,
                })}
                key={tab.label}
                role="tab"
                onClick={tab.link.onClick}
                href={tab.link.href}
              >
                <div className="flex h-12 items-center gap-2">
                  <Icon />
                  <div className="text-base leading-4 text-steel-500">{tab.label}</div>
                </div>
              </a>
            );
          })}
        </div>
      </nav>
    </nav>
  );
}
