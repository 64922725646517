/* eslint-disable max-len */
import { colors } from '@newfront-insurance/core-ui';

interface Props {
  color?: string;
}

export type IconComponent = ({ color }: Props) => JSX.Element;

export function PlanIcon({ color = colors.steel[500] }: Props): JSX.Element {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6666 1L14.9999 3.33333L7.53327 10.8L4.2666 11.7333L5.19993 8.46667L12.6666 1Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8 2.86664L13.1334 5.19997"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1333 9.39997V14.0666C13.1333 14.5818 12.7152 15 12.2 15H1.93333C1.41813 15 1 14.5818 1 14.0666V3.79997C1 3.28477 1.41813 2.86664 1.93333 2.86664H6.6"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SubmitIcon({ color = colors.steel[500], className }: Props & { className?: string }): JSX.Element {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M5.28564 6.99988H13.8571"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4285 3.57123L13.857 6.9998L10.4285 10.4284"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.85714 13H1.85714C1.384 13 1 12.616 1 12.1429V1.85714C1 1.384 1.384 1 1.85714 1H7.85714"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function CompareIcon({ color = colors.steel[500] }: Props): JSX.Element {
  return (
    <svg width="20" height="9" viewBox="0 0 20 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 4.59967H11.8" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M15.4 1L11.8 4.6L15.4 8.2"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M1 4.59967H8.2" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M4.6001 1L8.2001 4.6L4.6001 8.2"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function PrepareIcon({ color = colors.steel[500] }: Props): JSX.Element {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.2667 1.93311H12.2C12.4475 1.93311 12.6849 2.03144 12.86 2.20647C13.035 2.38151 13.1333 2.6189 13.1333 2.86644V14.0664C13.1333 14.314 13.035 14.5514 12.86 14.7264C12.6849 14.9014 12.4475 14.9998 12.2 14.9998H1.93333C1.6858 14.9998 1.4484 14.9014 1.27337 14.7264C1.09833 14.5514 1 14.314 1 14.0664V2.86644C1 2.6189 1.09833 2.38151 1.27337 2.20647C1.4484 2.03144 1.6858 1.93311 1.93333 1.93311H2.86667"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9.40006 1H4.7334V2.86667H9.40006V1Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M4.2666 8.93356L6.13327 10.8002L9.8666 7.06689"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function FolderIcon({ color = colors.steel[500] }: Props): JSX.Element {
  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 3.4L5.8 1H1V11.8C1 12.1183 1.12643 12.4235 1.35147 12.6485C1.57652 12.8736 1.88174 13 2.2 13H13C13.3183 13 13.6235 12.8736 13.8485 12.6485C14.0736 12.4235 14.2 12.1183 14.2 11.8V3.4H7Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function FolderOpenIcon({ color = colors.steel[500] }: Props): JSX.Element {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 5.00001V3.00001H6.00001L5.00001 1H1V12"
        stroke={color}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 15L5.00001 7H16L13 15H2Z"
        stroke={color}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ProposeIcon({ color = colors.steel[500] }: Props): JSX.Element {
  return (
    <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.93333 14H1V1H12.2667V9.66667L7.93333 14Z"
        stroke={color}
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.93359 14V9.66663H12.2669"
        stroke={color}
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.60034 4.46667H9.66701"
        stroke={color}
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.60034 7.06665H9.66701"
        stroke={color}
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.60034 9.66663H5.33368"
        stroke={color}
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function BindIcon({ color = colors.steel[500] }: Props): JSX.Element {
  return (
    <svg width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.82764 11.3447L5.9155 7.25684" stroke={color} strokeWidth="0.75" strokeMiterlimit="10" />
      <path
        d="M8.03453 1L3.48281 3.06897L1.82764 11.3448L10.1035 9.68966L12.1725 5.13793L8.03453 1Z"
        stroke={color}
        strokeWidth="0.75"
        strokeMiterlimit="10"
      />
      <path
        d="M7.67094 7.25717C8.15572 6.77239 8.15572 5.9864 7.67094 5.50162C7.18615 5.01683 6.40016 5.01683 5.91538 5.50162C5.43059 5.9864 5.43059 6.77239 5.91538 7.25717C6.40016 7.74196 7.18615 7.74196 7.67094 7.25717Z"
        stroke={color}
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path d="M1 13H13.4138" stroke="#262D46" strokeWidth="0.75" strokeMiterlimit="10" strokeLinecap="square" />
    </svg>
  );
}

export function FilesIcon({ color = colors.brand[400] }: Props): JSX.Element {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 5.00001V3.00001H6.00001L5.00001 1H1V12"
        stroke={color}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 15L5.00001 7H16L13 15H2Z"
        stroke={color}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
